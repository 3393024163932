import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This page provides a complete list of all past documentation changelogs for `}<a parentName="p" {...{
        "href": "/"
      }}>{`docs.fastly.com`}</a>{`. We also provide a complete list of all `}<a parentName="p" {...{
        "href": "/changes/significant/archive"
      }}>{`past significant changelogs`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      